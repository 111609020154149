import React from "react";
import { Form, Input, Flex, Button } from "antd";

import { useGetUserDetails, usePutUserDetails } from "../../../../apis/useUserApi";

export default function ProfileForm() {
  const [form] = Form.useForm();
  const { data: userDetails } = useGetUserDetails();
  const putUserDetails = usePutUserDetails();

  React.useEffect(() => {
    if (userDetails) {
      form.setFieldsValue({
        firstName: userDetails.firstName,
        lastName: userDetails.lastName,
        email: userDetails.email,
        phone: userDetails.phone,
      });
    }
  }, [userDetails, form]);

  const onSubmit = (body) => {
    putUserDetails({
      body,
    });
  };

  return (
    <Flex
      vertical={true}
      gap={12}
    >
      <Form
        name={"profile-form"}
        layout="vertical"
        form={form}
        onFinish={onSubmit}
      >
        <Flex gap={12}>
          <Form.Item
            label="First Name"
            name="firstName"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Last Name"
            name="lastName"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Flex>
        <Flex gap={12}>
          <Form.Item
            label="Email"
            name="email"
            type="email"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Phone"
            name="phone"
          >
            <Input />
          </Form.Item>
        </Flex>
      </Form>
      <Button
        onClick={form.submit}
        type="primary"
        style={{ alignSelf: "flex-end" }}
      >
        Save Changes
      </Button>
    </Flex>
  );
}
