import React from "react";
import Loading from "../../../../components/Loading/Loading";
import { usePostSubscriptionPortal } from "../../../../apis/useSubscriptionApi";

export default function BillingForm() {
  const postSubscriptionPortal = usePostSubscriptionPortal();

  React.useEffect(() => {
    postSubscriptionPortal(
      {},
      {
        onSuccess: async (response) => {
          window.location.href = response.data.url;
        },
      }
    );
  }, []);

  return (
    <div style={{ height: "100%", width: "100%" }}>
      <Loading />
    </div>
  );
}
