import React from "react";
import apiEndpoints from "./utils/apiEndpoints";
import queryKeys from "./utils/queryKeys";
import useGetApi from "./utils/useGetApi";
import useMutateApi from "./utils/useMutateApi";

export const useGetProducts = () => {
  return useGetApi({
    url: `${apiEndpoints.stripe.products}`,
    queryKey: queryKeys.stripe.product,
    // params: { productId },
    // successMsg: "Successfully posted"
  });
};

export const useGetProduct = (productId) => {
  return useGetApi({
    url: `${apiEndpoints.stripe.products}/${productId}`,
    queryKey: queryKeys.stripe.product,
    // params: { productId },
    // successMsg: "Successfully posted"
  });
};

export const useGetUserStripePlan = () => {
  return useGetApi({
    url: `${apiEndpoints.stripe.currentUserStripePlan}`,
    queryKey: queryKeys.stripe.currentUserStripePlan,
    // params: { productId },
    // successMsg: "Successfully posted"
  });
};
