import React from "react";
import dayjs from "dayjs";
import hasCircularStructure from "../../../../utils/hasCircularStructure";
import useTokenUsage from "../../../../utils/useTokenUsage";

//components
import { Input, Space, Button, Flex, Tooltip, Dropdown, Upload, Typography } from "antd";
import FeatherIcon from "feather-icons-react";
import useWorkflowStore from "../../../../context/stores/useWorkflowStore";
import useWorkflowContext from "../../../../context/useWorkflowContext";
import useChatStore from "../../../../context/stores/useChatStore";
import IncomingMessage from "./components/IncomingMessage/IncomingMessage";
import OutgoingMessage from "./components/OutgoingMessage/OutgoingMessage";
import useMediaGallery from "../../../../components/useMediaGallery/useMediaGallery";
import useMedia from "../../../../utils/useMedia";
import Attachment from "../../../../components/Attachment/Attachment";

// apis
import { usePostSendMessage } from "../../../../apis/useChatApi";
import { useGetUserStripePlan } from "../../../../apis/useStripeApi";
import { useGetUserDetails } from "../../../../apis/useUserApi";
import { useGetTokenCounter } from "../../../../apis/useDashboardApi";

import "./Chat.scss";

const { Text } = Typography;

export default function Chat() {
  const { data: currentUserStripePlan } = useGetUserStripePlan();
  const { refetch: refetchUserDetails } = useGetUserDetails();
  const { refetch: refetchTokenCounter } = useGetTokenCounter();
  const { isOverFreeLimit } = useTokenUsage();
  const [message, setMessage] = React.useState("");
  const { setOpenChat, selectedWorkflow } = useWorkflowStore();
  const postSendMessage = usePostSendMessage();
  const { nodes, edges } = useWorkflowContext();
  const { chatHistory, setChatHistory, isMessageLoading, setIsMessageLoading } = useChatStore();
  const { MediaGalleryModalComponent, onOpenMediaGallery } = useMediaGallery();
  const { onGetAwsUploadCredentials, onUpload: onUploadMedia, doc } = useMedia();
  const [selectedDoc, setSelectedDoc] = React.useState(null);

  const onSendMessage = () => {
    setIsMessageLoading(true);
    const outgoingMsg = {
      type: "outgoing",
      content: message,
      timestamp: dayjs().toString(),
    };

    const history = [...chatHistory, outgoingMsg];
    setChatHistory(history);

    postSendMessage(
      {
        body: {
          message: message,
          workflowId: selectedWorkflow.id,
        },
      },
      {
        onSuccess: (data) => {
          const incomingMsg = {
            type: "incoming",
            content: data.data.data,
            timestamp: dayjs().toString(),
          };
          history.push(incomingMsg);
          setChatHistory(history);
        },
        onError: (error) => {
          console.log(error);
        },
        onSettled: () => {
          setIsMessageLoading(false);
          if (currentUserStripePlan?.name === "Free") {
            refetchUserDetails();
          } else {
            refetchTokenCounter();
          }
        },
      }
    );

    setMessage("");
  };

  const onPressEnter = (e) => {
    if (e.key === "Enter") {
      onSendMessage();
    }
  };

  React.useEffect(() => {
    setSelectedDoc(doc);
  }, [doc]);

  const circurlarStructureDetected = React.useMemo(() => {
    return hasCircularStructure(nodes, edges);
  }, [JSON.stringify(nodes.map((node) => node.id)), JSON.stringify(edges.map((edge) => edge.id))]);

  const tooltipMessage = React.useMemo(() => {
    if (isOverFreeLimit) {
      return "You have reached the limit of 100,000 tokens. Please upgrade to increase your quota.";
    } else if (!selectedWorkflow?.id) {
      return "Please select a workflow.";
    }

    return false;
  }, [isOverFreeLimit, selectedWorkflow]);

  const onUpload = async (options) => {
    const { file, uploadCreds } = await onGetAwsUploadCredentials(options);
    await onUploadMedia({ file, uploadCredentials: uploadCreds });
  };

  const items = [
    {
      key: "clear",
      label: "Clear chat",
      icon: (
        <FeatherIcon
          icon={"trash"}
          size={12}
        />
      ),
      onClick: () => setChatHistory([]),
      disabled: chatHistory.length === 0,
    },
    {
      key: "documents",
      label: "Document library",
      icon: (
        <FeatherIcon
          icon={"book"}
          size={12}
        />
      ),
      onClick: onOpenMediaGallery,
    },
    {
      key: "attach",
      icon: (
        <FeatherIcon
          icon={"paperclip"}
          size={12}
        />
      ),
      label: (
        <Upload
          customRequest={onUpload}
          showUploadList={false}
        >
          Attach a document
        </Upload>
      ),
    },
  ];

  return (
    <div
      className="chat-wrapper"
      onKeyDown={onPressEnter}
    >
      <div className="chat--header">
        <FeatherIcon
          icon="chevron-left"
          size={20}
          onClick={() => setOpenChat(false)}
        />
      </div>
      <div className="chat--body">
        <Flex
          vertical={true}
          className="msgs-container"
        >
          {chatHistory.map((msg, idx) =>
            msg.type === "outgoing" ? (
              <OutgoingMessage
                key={idx}
                message={msg}
              />
            ) : (
              <IncomingMessage
                key={idx}
                message={msg}
              />
            )
          )}
          {isMessageLoading && (
            <IncomingMessage
              message={null}
              isMessageLoading={isMessageLoading}
            />
          )}
        </Flex>
      </div>
      <Flex
        className="chat--footer"
        vertical
        gap={6}
      >
        {selectedDoc && (
          <Attachment
            filename={selectedDoc?.name}
            onRemove={() => setSelectedDoc(null)}
          />
        )}
        <Space.Compact className="input-container">
          <Dropdown
            menu={{ items }}
            placement={"topRight"}
          >
            <Button
              icon={
                <FeatherIcon
                  icon={"plus"}
                  size={12}
                />
              }
            ></Button>
          </Dropdown>
          <Input
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
          <Tooltip
            open={tooltipMessage ? undefined : false}
            title={tooltipMessage}
          >
            <Button
              type="primary"
              disabled={isMessageLoading || !selectedWorkflow?.id || isOverFreeLimit || circurlarStructureDetected}
              icon={
                <FeatherIcon
                  icon={"send"}
                  size={16}
                />
              }
              onClick={onSendMessage}
            ></Button>
          </Tooltip>
        </Space.Compact>
      </Flex>
      {MediaGalleryModalComponent}
    </div>
  );
}
