import apiEndpoints from "./utils/apiEndpoints";
import axios from "axios";
import queryKeys from "./utils/queryKeys";
import useGetApi from "./utils/useGetApi";
import useMutateApi from "./utils/useMutateApi";
import useErrorHandler from "./utils/useErrorHandler";
import useCookies from "./utils/useCookies";
import { ACCESS_TOKEN_KEY, APP_STATE } from "../constants/auth";

export const useGetDocumentsApi = () => {
  return useGetApi({
    url: apiEndpoints.documents.documents,
    queryKey: queryKeys.documents.all,
  });
};

export const usePostDocumentApi = () => {
  return useMutateApi({
    url: apiEndpoints.documents.documents,
    method: "post",
    invalidateQueries: [queryKeys.documents.all],
  });
};

export const useDocumentsApi = () => {
  const { getCookie } = useCookies();
  const { handleError } = useErrorHandler();

  const getAwsUploadCreds = async (filename) => {
    const accessToken = getCookie(ACCESS_TOKEN_KEY);
    const resp = await axios.get(
      `${apiEndpoints.documents.uploadCredentials}?filename=${filename}&storageId=document`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return resp.data;
  };

  const uploadToAws = async (file, uploadCredentials) => {
    try {
      const url = uploadCredentials.url;
      const formData = new FormData();

      for (const [key, value] of Object.entries(uploadCredentials.fields || {})) {
        formData.append(key, value);
      }
      formData.append("file", file);

      const response = await axios.post(url, formData);

      if (response.ok === false) {
        throw new Error(response.statusText);
      }

      return response?.data;
    } catch (error) {
      handleError(error);
    }
  };

  return {
    getAwsUploadCreds,
    uploadToAws,
  };
};
