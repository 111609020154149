import apiEndpoints from "./utils/apiEndpoints";
import queryKeys from "./utils/queryKeys";
import useGetApi from "./utils/useGetApi";
import useMutateApi from "./utils/useMutateApi";

const useGetTenantWorkflows = () => {
  return useGetApi({
    url: apiEndpoints.workflows,
    queryKey: queryKeys.privateWorkflows.all,
  });
};

const usePostTenantWorkflow = () => {
  return useMutateApi({
    url: apiEndpoints.workflows,
    method: "post",
    invalidateQueries: [queryKeys.privateWorkflows.all],
    successMsg: "Workflow saved! 😎",
  });
};

const usePutTenantWorkflow = () => {
  return useMutateApi({
    url: apiEndpoints.workflows,
    method: "put",
    invalidateQueries: [queryKeys.privateWorkflows.all],
    successMsg: "Workflow updated! 😎",
  });
};

const useDeleteTenantWorkflow = (request) => {
  return useMutateApi({
    url: apiEndpoints.workflows,
    method: "delete",
    invalidateQueries: [queryKeys.privateWorkflows.all],
    successMsg: "Workflow deleted! 👍",
  });
};

export { useGetTenantWorkflows, usePostTenantWorkflow, usePutTenantWorkflow, useDeleteTenantWorkflow };
