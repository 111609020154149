import React from "react";
import { useSearchParams } from "react-router-dom";
import ContentWrapper from "../components/ContentWrapper/ContentWrapper";
import { Spin, Flex } from "antd";
import useAuthApi from "../../../apis/useAuthApi";

export default function ConfirmEmail() {
  const { confirmEmail } = useAuthApi();
  const [searchParams] = useSearchParams();

  React.useEffect(() => {
    const tempToken = searchParams.get("token");
    if (tempToken) {
      confirmEmail(tempToken);
    }
  }, [confirmEmail, searchParams]);

  return (
    <ContentWrapper
      title={"Confirming Email"}
      subtitle={"Please wait"}
    >
      <Flex justify="center">
        <Spin />
      </Flex>
    </ContentWrapper>
  );
}
