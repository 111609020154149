import React from "react";
import { useGetUserDetails } from "../../../../apis/useUserApi";
import Grid from "../../../../components/Grid/Grid";

import "./Tools.scss";

const toolsRowData = [
  {
    id: "google-tools",
    name: "Google Tools",
    description:
      "Streamline your workflow with Gmail and Google Drive integration. Create email drafts in Gmail, plus read and create Google Drive files. Edit capabilities are limited to files created by the GAS engine.",
    isActive: false,
  },
];

export default function Tools() {
  const { data: userDetails } = useGetUserDetails();
  const [rowData, setRowData] = React.useState(toolsRowData);

  const gridOptions = React.useMemo(() => {
    return {
      columnDefs: [
        { headerName: "Tool", field: "name" },
        { headerName: "Description", field: "description", flex: 2 },
        {
          headerName: "Enabled?",
          field: "isActive",
          cellRenderer: "EnableToolCellRenderer",
        },
      ],
    };
  }, []);

  React.useEffect(() => {
    if (userDetails) {
      const rowData = toolsRowData.map((tool) => {
        if (tool.id === "google-tools") {
          return {
            ...tool,
            isActive: userDetails?.currentTenant?.gToolsEnabled,
          };
        }

        return tool;
      });
      setRowData(rowData);
    }
  }, [userDetails]);

  return (
    <Grid
      rowData={rowData}
      gridOptions={gridOptions}
    />
  );
}
