import { useShallow } from "zustand/react/shallow";
import { kebabCase } from "lodash";
import { v4 } from "uuid";
import useWorkflowStore from "../stores/useWorkflowStore";
import useWorkflowContext from "../useWorkflowContext";
import uniqueWorkflowTitle from "../../utils/uniqueWorkflowTitle";
import {
  useGetTenantWorkflows,
  usePostTenantWorkflow,
  usePutTenantWorkflow,
  useDeleteTenantWorkflow,
} from "../../apis/useWorkflowApi";

export default function useWorkflowActions() {
  const { data: workflows } = useGetTenantWorkflows();
  const postUserWorkflow = usePostTenantWorkflow();
  const putUserWorkflow = usePutTenantWorkflow();
  const deleteUserWorkflow = useDeleteTenantWorkflow();
  const { nodes, edges, setNodes, setEdges } = useWorkflowContext();

  const { setEditAgent, setOpenAgentModal, workflowTitle, setWorkflowTitle, selectedWorkflow, setSelectedWorkflow } =
    useWorkflowStore(
      useShallow((state) => ({
        setEditAgent: state.setEditAgent,
        setOpenAgentModal: state.setOpenAgentModal,
        workflowTitle: state.workflowTitle,
        setWorkflowTitle: state.setWorkflowTitle,
        selectedWorkflow: state.selectedWorkflow,
        setSelectedWorkflow: state.setSelectedWorkflow,
      }))
    );

  const onAddAgent = async (data) => {
    const id = `${kebabCase(data.name)}:${v4()}`;
    const newNode = {
      id,
      key: id,
      type: "StandardNode",
      position: {
        x: nodes.length + 20,
        y: 0,
      },
      data: {
        ...data,
        id,
      },
      origin: [0.5, 0.0],
    };
    const updatedNodes = [...nodes, newNode];
    await setNodes(updatedNodes);
    if (selectedWorkflow) onUpdateWorkflow({ nodes: updatedNodes, successMsg: "Successfully added agent 😁" });
    else onSaveWorkflow({ nodes: updatedNodes, successMsg: "Successfully saved workflow" });
  };

  const onUpdateAgent = async (data) => {
    const foundIndex = nodes.findIndex((node) => node.id === data.id);
    if (foundIndex !== -1) {
      const updatedNodes = [...nodes];
      updatedNodes[foundIndex].data = data;
      await setNodes(updatedNodes);

      if (selectedWorkflow) onUpdateWorkflow({ nodes: updatedNodes, successMsg: "Successfully updated agent 👍" });
    }
  };

  const onSelectAgent = (agent) => {
    setEditAgent(agent);
    setOpenAgentModal(true);
  };

  const onSaveWorkflow = ({ nodes: nodesProp, successMsg }) => {
    const newNodes = nodesProp || nodes;

    const workflowNames = workflows?.privateWorkflows?.map((item) => item.name) || [];
    const uniqueTitle = uniqueWorkflowTitle(workflowTitle, workflowNames);
    postUserWorkflow(
      {
        body: {
          name: uniqueTitle,
          description: "Test",
          type: "Sequential",
          config: JSON.stringify({
            nodes: newNodes,
            edges,
          }),
          isPrivate: true,
        },
        successMsg,
      },
      {
        onSuccess: (resp) => {
          setSelectedWorkflow(resp.data);
        },
      }
    );
  };

  const onUpdateWorkflow = ({ nodes: nodesProp, successMsg }) => {
    const newNodes = nodesProp || nodes;

    putUserWorkflow({
      id: selectedWorkflow.id,
      body: {
        name: workflowTitle,
        description: "Test",
        type: "Sequential",
        config: JSON.stringify({
          nodes: newNodes,
          edges,
        }),
        isPrivate: true,
      },
      successMsg,
    });
  };

  const onDeleteWorkflow = () => {
    deleteUserWorkflow(
      {
        params: {
          ids: [selectedWorkflow.id],
        },
      },
      {
        onSuccess: () => {
          setWorkflowTitle("(Untitled)");
          setEdges([]);
          setNodes([]);
          setSelectedWorkflow(null);
        },
      }
    );
  };

  return {
    onAddAgent,
    onUpdateAgent,
    onSelectAgent,
    onSaveWorkflow,
    onUpdateWorkflow,
    onDeleteWorkflow,
  };
}
