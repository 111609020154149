import axios from "axios";
import { useNavigate } from "react-router-dom";
import useCookies from "./utils/useCookies";
import { ACCESS_TOKEN_KEY, APP_STATE } from "../constants/auth";
import useAppStore from "../context/stores/useAppStore";
import { useGoogleLogin } from "@react-oauth/google";
import { useGetUserDetails } from "./useUserApi";
import { useQueryClient } from "@tanstack/react-query";

export default function useGoogleApis() {
  const { setCookie } = useCookies();
  const { data: user, refetch: refetchUser } = useGetUserDetails();
  const { setAppState } = useAppStore();

  const authenticateUser = (token) => {
    setCookie(ACCESS_TOKEN_KEY, token);
    if (user?.memberships?.length === 0) setAppState(APP_STATE.noTenant);
    else setAppState(APP_STATE.authenticated);
  };

  const signinWithGoogle = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      try {
        // Send the access token to your backend for further processing
        const resp = await axios.get("/api/auth/google/rest", {
          params: {
            code: tokenResponse.code,
            oauthProvider: "google",
            requestType: "rest",
          },
        });

        // Handle the resp from your backend
        authenticateUser(resp.data.token);
        // refetchUser();
        // Save the token to localStorage or context as needed
      } catch (error) {
        console.error("OAuth login failed:", error);
      }
    },
    flow: "auth-code",
    redirect_uri: process.env.REACT_APP_FRONTEND_URL, // This should match one of the authorized redirect URIs in your Google Cloud project
    scope: ["https://www.googleapis.com/auth/userinfo.email", "https://www.googleapis.com/auth/userinfo.profile"].join(
      " "
    ),
  });

  const signinWithGoogleTools = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      try {
        // Send the access token to your backend for further processing
        const resp = await axios.get("/api/auth/google/rest", {
          params: {
            code: tokenResponse.code,
            oauthProvider: "google",
            requestType: "rest",
            enableTool: true,
            tenantId: user.currentTenant.id,
          },
        });

        // Handle the resp from your backend
        authenticateUser(resp.data.token);
        refetchUser();
        // Save the token to localStorage or context as needed
      } catch (error) {
        console.error("OAuth login failed:", error);
      }
    },
    flow: "auth-code",
    redirect_uri: process.env.REACT_APP_FRONTEND_URL, // This should match one of the authorized redirect URIs in your Google Cloud project
    scope: [
      "https://www.googleapis.com/auth/userinfo.email",
      "https://www.googleapis.com/auth/userinfo.profile",
      "https://www.googleapis.com/auth/gmail.compose",
      "https://www.googleapis.com/auth/drive.file",
      "https://www.googleapis.com/auth/drive.readonly",
    ].join(" "),
  });

  return {
    signinWithGoogle,
    signinWithGoogleTools,
  };
}
