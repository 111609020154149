import React from "react";
import { Button, Flex, Typography, theme } from "antd";
import FeatherIcon from "feather-icons-react";

import useGoogleApi from "../../../../apis/useGoogleApi";

const { Text } = Typography;
const { useToken } = theme;

export default function EnableToolCellRenderer(params) {
  const { token } = useToken();
  const { signinWithGoogleTools } = useGoogleApi();

  const handleEnable = () => {
    if (params.data.id === "google-tools") {
      signinWithGoogleTools();
    }
  };

  if (params.value === true) {
    return (
      <div>
        <FeatherIcon
          icon={"check"}
          size={12}
          color={token.colorSuccess}
          style={{ marginRight: 4, paddingTop: 4 }}
        />
        <Text type="success">Enabled</Text>
      </div>
    );
  }
  return (
    <Button
      onClick={handleEnable}
      type="primary"
    >
      Enable
    </Button>
  );
}
