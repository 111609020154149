import apiEndpoints from "./utils/apiEndpoints";
import queryKeys from "./utils/queryKeys";
import useGetApi from "./utils/useGetApi";

export const useGetTokenCounter = () => {
  return useGetApi({
    url: apiEndpoints.dashboard.tokenCounter,
    queryKey: queryKeys.dashboard.tokenCounter,
  });
};
