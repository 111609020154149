import React from "react";
import ReactFlowPkg, { Background, Controls, applyNodeChanges, applyEdgeChanges, addEdge } from "reactflow";
import useWorkflowContext from "../../../../context/useWorkflowContext";
import StandardNode from "./components/StandardNode/StandardNode";
import useWorkflowActions from "../../../../context/actions/useWorkflowActions";
import useWorkflowStore from "../../../../context/stores/useWorkflowStore";

const nodeTypes = { StandardNode };

export default function ReactFlow() {
  const { nodes, edges, setNodes, setEdges } = useWorkflowContext();
  const { selectedWorkflow } = useWorkflowStore();

  const { onUpdateWorkflow } = useWorkflowActions();

  const onNodesChange = React.useCallback((changes) => setNodes((nds) => applyNodeChanges(changes, nds)), []);

  const onEdgesChange = React.useCallback((changes) => {
    setEdges((eds) => applyEdgeChanges(changes, eds));
  }, []);

  const onNodesDelete = React.useCallback(() => {
    if (selectedWorkflow) onUpdateWorkflow({ successMsg: "Successfully deleted agent 🫣" });
  }, []);

  const onConnect = React.useCallback((connection) => setEdges((eds) => addEdge(connection, eds)), []);

  return (
    <ReactFlowPkg
      nodes={nodes}
      edges={edges}
      onNodesChange={onNodesChange}
      onEdgesChange={onEdgesChange}
      onNodesDelete={onNodesDelete}
      onConnect={onConnect}
      nodeTypes={nodeTypes}
      fitView
    >
      {/* <MiniMap /> */}
      <Controls />
      <Background />
    </ReactFlowPkg>
  );
}
