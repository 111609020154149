import React from "react";
import MediaGalleryModal from "./components/MediaGalleryModal/MediaGalleryModal";

export default function useMediaGallery() {
  const [openModal, setOpenModal] = React.useState(false);

  const onOpenMediaGallery = () => {
    setOpenModal(true);
  };

  const onCloseMediaGallery = () => {
    setOpenModal(false);
  };

  const MediaGalleryModalComponent = (
    <MediaGalleryModal
      open={openModal}
      onCancel={onCloseMediaGallery}
    />
  );

  return {
    MediaGalleryModalComponent,
    onOpenMediaGallery,
    onCloseMediaGallery,
  };
}
