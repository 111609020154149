const MODELS = [
  {
    value: "claude-3-haiku-20240307",
    label: "Claude 3 Haiku",
  },
];

const TOOLS = [
  {
    value: 1,
    label: "Web Search (Tavily)",
  },
  {
    value: 2,
    label: "Web Search (DuckDuckGo)",
  },
  {
    value: 3,
    label: "Email Draft (Gmail)",
  },
  {
    value: 4,
    label: "Email Schedule Send (Gmail)",
  },
  {
    value: 5,
    label: "Read Sheet (Google Drive)",
  },
  {
    value: 6,
    label: "Write to Sheet (Google Drive)",
  },
  {
    value: 7,
    label: "Email Deliverability",
  },
  {
    value: 8,
    label: "Web Scraper",
  },
].sort((a, b) => a.label - b.label);

export { MODELS, TOOLS };
