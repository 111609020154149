import apiEndpoints from "./utils/apiEndpoints";
import queryKeys from "./utils/queryKeys";
import useGetApi from "./utils/useGetApi";
import useMutateApi from "./utils/useMutateApi";

const useGetUserDetails = () => {
  return useGetApi({
    url: apiEndpoints.user.details,
    queryKey: queryKeys.user.details,
  });
};

const usePutUserDetails = () => {
  return useMutateApi({
    url: apiEndpoints.user.details,
    method: "put",
    invalidateQueries: [queryKeys.user.details],
    successMsg: "Successfully updated profile ⚡️",
  });
};

export { useGetUserDetails, usePutUserDetails };
