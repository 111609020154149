const queryKeys = {
  dashboard: {
    tokenCounter: ["tokenCounter"],
  },
  documents: {
    all: ["documents"],
  },
  privateWorkflows: {
    all: ["privateWorkflows"],
  },
  stripe: {
    portal: ["portal"],
    product: ["product"],
    products: ["products"],
    currentUserStripePlan: ["currentUserStripePlan"],
  },
  user: {
    details: ["userDetails"],
    tenants: ["userDetails", "tenants"],
    memberships: ["userDetails", "memberships"],
  },
};

export default queryKeys;
