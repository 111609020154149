import React from "react";
import { Modal as AntModal } from "antd";

export default function Modal(props) {
  const { open, setOpen, children, ...moreProps } = props;

  return (
    <AntModal
      open={open}
      onCancel={() => setOpen(false)}
      {...moreProps}
    >
      <div style={{ paddingBottom: 24 }}>{children}</div>
    </AntModal>
  );
}
