import React from "react";
import { APP_STATE } from "../../constants/auth";
import { Navigate, Outlet } from "react-router-dom";

// context
import useAppStore from "../../context/stores/useAppStore";

const ProtectedRoutes = () => {
  const { appState } = useAppStore();

  if (appState === APP_STATE.unverified)
    return <Navigate to={"/verify-email"} />;
  else if (appState === APP_STATE.unauthenticated)
    return <Navigate to={"/signin"} />;
  else if (appState === APP_STATE.noTenant)
    return <Navigate to={"/create-workspace"} />;
  else if (appState === APP_STATE.authenticated) return <Outlet />;
};

export default ProtectedRoutes;
