import React from "react";
import { useNavigate, Link } from "react-router-dom";

import { Button, Form, Input, theme, Typography, Checkbox, Flex } from "antd";

import { LockOutlined, MailOutlined, UserOutlined } from "@ant-design/icons";
import ContentWrapper from "../components/ContentWrapper/ContentWrapper";
import useAuthApi from "../../../apis/useAuthApi";
const { useToken } = theme;
const { Text } = Typography;

export default function Signup() {
  const [isSubmitLoading, setIsSubmitLoading] = React.useState(false);
  const navigate = useNavigate();
  const { token } = useToken();
  const { signup } = useAuthApi();

  const styles = {
    forgotPassword: {
      float: "right",
    },
    signup: {
      marginTop: token.marginLG,
      textAlign: "center",
      width: "100%",
    },
  };

  const onSignup = async (creds) => {
    setIsSubmitLoading(true);
    await signup(creds);
    navigate("/verify-email");
    setIsSubmitLoading(false);
  };

  return (
    <ContentWrapper
      title={"Sign up"}
      subtitle={"Create an account to get started."}
    >
      <Form
        name="normal_signup"
        onFinish={onSignup}
        layout="vertical"
        requiredMark="optional"
      >
        <Flex
          vertical={true}
          gap={6}
        >
          <Form.Item
            name="firstName"
            rules={[
              {
                required: true,
                message: "Please enter first name!",
              },
            ]}
          >
            <Input
              prefix={<UserOutlined />}
              placeholder="First name"
            />
          </Form.Item>
          <Form.Item
            name="lastName"
            rules={[
              {
                required: true,
                message: "Please enter last name!",
              },
            ]}
          >
            <Input
              prefix={<UserOutlined />}
              placeholder="Last name"
            />
          </Form.Item>
          <Form.Item
            name="email"
            rules={[
              {
                type: "email",
                required: true,
                message: "Please enter email!",
              },
            ]}
          >
            <Input
              prefix={<MailOutlined />}
              placeholder="Email"
            />
          </Form.Item>
          <Form.Item
            name="password"
            extra="Password needs to be at least 10 characters."
            rules={[
              {
                required: true,
                message: "Please enter password!",
              },
              {
                pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>/?]).{10,}$/,
                message: "Password must contain at least one uppercase letter, one lowercase letter, and one number",
              },
            ]}
          >
            <Input.Password
              prefix={<LockOutlined />}
              type="password"
              placeholder="Password"
            />
          </Form.Item>
          <Form.Item
            name="confirmPassword"
            rules={[
              {
                required: true,
                message: "Please confirm password!",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error("The passwords you entered do not match!"));
                },
              }),
            ]}
          >
            <Input.Password
              prefix={<LockOutlined />}
              type="confirmPassword"
              placeholder="Confirm password"
            />
          </Form.Item>
          <Form.Item
            name="tandc"
            valuePropName="checked"
            rules={[
              {
                required: true,
                message: "Please accept the terms and conditions to sign up",
              },
            ]}
          >
            <Checkbox>
              I accept the <a href="https://tktech.ai/terms-conditions/">Terms & Conditions</a>
            </Checkbox>
          </Form.Item>
        </Flex>

        <Form.Item style={{ marginTop: 24, marginBottom: "0px" }}>
          <Button
            block
            type="primary"
            htmlType="submit"
            loading={isSubmitLoading}
          >
            Sign up
          </Button>
          <div style={styles.signup}>
            <Text type="secondary">Already have an account?</Text> <Link to="/signin">Sign in</Link>
          </div>
        </Form.Item>
      </Form>
    </ContentWrapper>
  );
}
