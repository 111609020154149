import React from "react";
import { Button, Drawer, Grid, theme } from "antd";

import { MenuOutlined } from "@ant-design/icons";
import useNavigationComponents from "../useNavigationComponents";

const { useToken } = theme;
const { useBreakpoint } = Grid;

const MobileNav = () => {
  const { menus, open, setOpen } = useNavigationComponents;
  const { token } = useToken();
  const screens = useBreakpoint();

  const styles = {
    navbarContainer: {
      alignItems: "center",
      display: "flex",
      justifyContent: "space-between",
      margin: "0 auto",
      padding: `${token.paddingXS}px ${token.padding}px`,
    },
    navbarMobile: {
      display: screens.sm ? "none" : "block",
    },
  };

  return (
    <div style={styles.navbarMobile}>
      <div style={styles.navbarContainer}>
        {/* <Logo showText={shouldDisplayOnMobile} /> */}
        <Button
          type="text"
          icon={<MenuOutlined />}
          onClick={() => setOpen(true)}
        />
        <Drawer
          title="Menu"
          placement="right"
          onClose={() => setOpen(false)}
          open={open}
          style={{
            body: {
              padding: 0,
            },
          }}
        >
          {menus}
        </Drawer>
      </div>
    </div>
  );
};

export default MobileNav;
