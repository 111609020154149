import React from "react";
import { AgGridReact } from "ag-grid-react"; // React Data Grid Component

// cell renderers
import EnableToolCellRenderer from "./cellRenderers/EnableToolCellRenderer/EnableToolCellRenderer";

import "./Grid.scss";

export default function Grid({ rowData, gridOptions: gridOptionsProps, onGridReady, ...moreProps }) {
  const gridOptions = {
    ...gridOptionsProps,
    defaultColDef: {
      sortable: true,
      wrapText: true,
      autoHeight: true,
    },
    components: {
      EnableToolCellRenderer,
    },
  };

  return (
    <div
      className="ag-theme-quartz ag-theme-gas" // applying the Data Grid theme
      style={{ height: "100%", width: "100%" }} // the Data Grid will fill the size of the parent container
    >
      <AgGridReact
        rowData={rowData}
        gridOptions={gridOptions}
        domLayout={"autoHeight"}
        onGridReady={onGridReady}
        {...moreProps}
      />
    </div>
  );
}
