import React from "react";
import { useQueryClient } from "@tanstack/react-query";
import queryKeys from "../../apis/utils/queryKeys";
import { Row, theme } from "antd";
import ContentLayout from "../../layouts/ContentLayout/ContentLayout";
import { useGetUserDetails } from "../../apis/useUserApi";
import useTokenUsage from "../../utils/useTokenUsage";

const { useToken } = theme;

export default function Dashboard() {
  const queryClient = useQueryClient();
  const { token } = useToken();
  const { data: user } = useGetUserDetails();
  const { renderTokenUsageCards } = useTokenUsage();

  React.useEffect(() => {
    queryClient.invalidateQueries({ queryKey: queryKeys.dashboard.tokenCounter });
  }, []);

  return (
    <ContentLayout title={user?.currentTenant?.name}>
      <Row
        gutter={[
          {
            xs: token.size,
            sm: token.size,
            md: token.size,
            lg: token.sizeLG,
            xl: token.sizeLG,
          },
          token.size,
        ]}
      >
        {renderTokenUsageCards()}
      </Row>
    </ContentLayout>
  );
}
