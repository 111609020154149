import React from "react";
import dayjs from "dayjs";
import { Card, Col, Grid, Row, Statistic, theme, Typography } from "antd";
import { useGetUserDetails } from "../apis/useUserApi";
import { useGetTokenCounter } from "../apis/useDashboardApi";
import { useGetUserStripePlan } from "../apis/useStripeApi";

const { useToken } = theme;
const { useBreakpoint } = Grid;
const { Text } = Typography;

export default function useTokenUsage() {
  const { data: user } = useGetUserDetails();
  const { data: tokenCounter } = useGetTokenCounter();
  const { data: userStripePlan } = useGetUserStripePlan();

  const { token } = useToken();
  const screens = useBreakpoint();

  const styles = {
    card: {
      position: "relative",
    },
    container: {
      // margin: "0 auto",
      maxWidth: screens.lg ? token.screenXL : token.screenSM,
      padding: screens.md ? `${token.paddingLG}px` : `${token.padding}px`,
    },
    section: {
      backgroundColor: token.colorBgContainer,
      // padding: `${token.sizeXXL}px 0px`
    },
  };

  const renderTokenUsageCards = () => {
    if (userStripePlan?.name === "Free") {
      if (!user?.currentTenant?.tokenUsage?.models) return [];
      return Object.keys(user.currentTenant.tokenUsage.models).map((key, i) => {
        const item = user.currentTenant.tokenUsage.models[key];

        return [
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={6}
            xl={6}
            key={`${i}-input`}
          >
            <Card style={{ body: styles.card }}>
              <Statistic
                title={`${item.label} - Input Tokens`}
                value={item.inputTokens}
              />
              {item?.period && (
                <Text type="secondary">
                  {dayjs.unix(item.period.start).format("DD MMM")} -{dayjs.unix(item.period.end).format("DD MMM")}
                </Text>
              )}
            </Card>
          </Col>,
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={6}
            xl={6}
            key={`${i}-output`}
          >
            <Card style={{ body: styles.card }}>
              <Statistic
                title={`${item.label} - Output Tokens`}
                value={item.outputTokens}
              />
              {item?.period && (
                <Text type="secondary">
                  {dayjs.unix(item.period.start).format("DD MMM")} -{dayjs.unix(item.period.end).format("DD MMM")}
                </Text>
              )}
            </Card>
          </Col>,
        ];
      });
    } else if (tokenCounter?.tokenUsage) {
      return tokenCounter.tokenUsage.map((item, i) => {
        return (
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={6}
            xl={6}
            key={`${i}-input`}
          >
            <Card style={{ body: styles.card }}>
              <Statistic
                title={`${item.nickname}`}
                value={item.quantity}
              />
              {item?.period && (
                <Text type="secondary">
                  {dayjs.unix(item.period.start).format("DD MMM")} -{dayjs.unix(item.period.end).format("DD MMM")}
                </Text>
              )}
            </Card>
          </Col>
        );
      });
    }
  };

  const isOverFreeLimit = React.useMemo(() => {
    if (userStripePlan?.name === "Free") {
      if (user?.currentTenant?.tokenUsage?.models) {
        const { models } = user.currentTenant.tokenUsage;
        for (let key in models) {
          if (models[key].inputTokens > 100000 || models[key].outputTokens > 100000) return true;
        }
      }
    }
    return false;
  }, [user?.currentTenant?.tokenUsage, userStripePlan?.name]);

  return {
    renderTokenUsageCards,
    isOverFreeLimit,
  };
}
