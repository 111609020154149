import apiEndpoints from "./utils/apiEndpoints";
import queryKeys from "./utils/queryKeys";
import useGetApi from "./utils/useGetApi";
import useMutateApi from "./utils/useMutateApi";

const usePostSendMessage = () => {
  return useMutateApi({
    url: apiEndpoints.sendMessage,
    method: "post",
    invalidateQueries: [],
  });
};

export { usePostSendMessage };
