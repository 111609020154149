import React from "react";

import {
  Grid,
  Layout,
  theme,
} from "antd";
import Logo from '../../../assets/images/icon-black.png'

import useNavigationComponents from "../useNavigationComponents";
import './Sidebar.scss';

const { Sider } = Layout;
const { useToken } = theme;
const { useBreakpoint } = Grid;

const Sidebar = () => {
  const { token } = useToken();
  const { menus, shouldDisplayOnMobile } = useNavigationComponents();
  const screens = useBreakpoint();

  token.colorFillAlter = "transparent";

  const styles = {
    sider: {
      backgroundColor: "transparent",
      display: screens.sm ? "block" : "none",
      flexDirection: "column",
    },
    logoWrapper: {
      padding: `${token.paddingLG}px 28px ${token.padding}px 28px`,
    },
    siderContent: {
      display: "flex",
      flexDirection: "column",
      height: "100%",
    },
  };

  return (
    <Sider
      className="sider-container"
      style={styles.sider}
      width={256}
      theme="light"
      trigger={null}
      breakpoint="lg"
      collapsed={true}
      collapsible
    >
      <div style={styles.siderContent}>
        <div style={styles.logoWrapper}>
          <img className="logo" src={Logo} alt="logo" />
        </div>
        {menus}
      </div>
    </Sider>
  );
};

export default Sidebar;