import React from "react";
import { Flex, Space, Typography, Avatar, Spin } from "antd";
import FeatherIcon from "feather-icons-react";
import formatTimestamp from "../../../../../../utils/formatTimestamp";

const { Text } = Typography;

export default function IncomingMessage({ message, isMessageLoading }) {
  return (
    <Flex
      className="msg msg-type--incoming"
      style={{ alignItems: isMessageLoading ? "center" : "flex-start" }}
    >
      <Space>
        <Avatar size={"small"} style={{ background: "#1B263B" }}>
          <FeatherIcon icon={"cpu"} size={14} />
        </Avatar>
      </Space>
      {isMessageLoading ? (
        <Spin size="small" />
      ) : (
        <Flex vertical={true}>
          <Text className="msg-content">{message?.content}</Text>
          <Text className="msg-timestamp" type="secondary">
            {formatTimestamp(message?.timestamp)}
          </Text>
        </Flex>
      )}
    </Flex>
  );
}
