import React from "react";
import { Card, Flex, Typography } from "antd";
import Button from "../../../../components/Button/Button";
import FeatherIcon from "feather-icons-react";
import { loadStripe } from "@stripe/stripe-js";
import { usePostSubscriptionCheckout, usePostSubscriptionPortal } from "../../../../apis/useSubscriptionApi";
import parseUnitAmountDecimal from "../../../../utils/parseUnitAmountDecimal";
import { useGetUserStripePlan } from "../../../../apis/useStripeApi";
import "./PriceCard.scss";

const { Title, Paragraph } = Typography;

export default function PriceCard({ product, subscription }) {
  const { data: userPlan } = useGetUserStripePlan();
  const postSubscriptionCheckout = usePostSubscriptionCheckout();
  // const postSubscriptionPortal = usePostSubscriptionPortal();
  const price = product?.prices?.[0];

  const onSubscribe = () => {
    postSubscriptionCheckout(
      {
        body: { stripePriceId: price.id },
      },
      {
        onSuccess: async (response) => {
          const stripe = await loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
          const { error } = await stripe.redirectToCheckout(response.data);
          if (error) {
            console.error(error);
          }
        },
      }
    );
  };

  // const onUpdatePlan = () => {
  //   postSubscriptionPortal(
  //     {},
  //     {
  //       onSuccess: async (response) => {
  //         window.location.href = response.data.url;
  //       },
  //     }
  //   );
  // };

  const isUsersPlan = userPlan?.name === price?.nickname;

  const [dollar] = parseUnitAmountDecimal(price.unit_amount_decimal);

  return (
    <Card className="price-card">
      <Flex justify="center">
        <Title level={1}>{price.nickname}</Title>
      </Flex>
      <Flex
        justify="center"
        align="end"
      >
        <Title
          level={1}
          style={{ marginBottom: ".3em" }}
        >
          ${dollar}
        </Title>
        <Paragraph level={1}> / user / month</Paragraph>
      </Flex>
      <Flex
        vertical={true}
        align="center"
        style={{ marginTop: 16 }}
      >
        {product.features.map((feature, i) => {
          return (
            <Flex
              align="start"
              justify="start"
              gap={8}
              key={i}
            >
              <FeatherIcon
                icon={"check"}
                size={14}
              />
              <Paragraph
                key={i}
                style={{ margin: 0, textAlign: "left" }}
                type="secondary"
              >
                {feature.name}
              </Paragraph>
            </Flex>
          );
        })}
      </Flex>
      <Flex
        justify="center"
        className="action-container"
      >
        <Button
          type="primary"
          style={{ width: "100%" }}
          disabled={isUsersPlan}
          onClick={onSubscribe}
        >
          {isUsersPlan ? "Current Plan" : "Select Plan"}
        </Button>
      </Flex>
    </Card>
  );
}
