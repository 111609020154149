import React from "react";
import { Outlet } from "react-router-dom";

// components
import { Grid, Layout, theme } from "antd";
import Sidebar from "../../components/navigation/Sidebar/Sidebar";
import MobileNav from "../../components/navigation/MobileNav/MobileNav";

const { Content } = Layout;

const { useToken } = theme;
const { useBreakpoint } = Grid;

export default function MainLayout() {
  const { token } = useToken();
  const screens = useBreakpoint();

  token.colorFillAlter = "transparent";

  const styles = {
    layout: {
      height: "100vh",
    },
    content: {
      padding: screens.sm
        ? `${token.padding}px ${token.padding}px ${token.padding}px 0`
        : "0",
    },
    outletContainer: {
      backgroundColor: token.colorBgContainer,
      borderRadius: token.borderRadiusLG,
      boxShadow: token.boxShadowTertiary,
    }
  };
  return (
    <Layout style={styles.layout}>
      <Sidebar />
      <Layout>
        <MobileNav />
        <Content style={styles.content}>
          <div className="workflow-container" style={styles.outletContainer}>
            <Outlet />
          </div>
        </Content>
      </Layout>
    </Layout>
  );
}
