import React from "react";
import ContentWrapper from "../components/ContentWrapper/ContentWrapper";
import { Typography, theme } from "antd";
import useAuthApi from "../../../apis/useAuthApi";

const { Text, Link } = Typography;
const { useToken } = theme;

export default function VerifyEmail() {
  const { token } = useToken();
  const { verifyEmail } = useAuthApi()

  const styles = {
    footer: {
      marginTop: token.marginLG,
      textAlign: "center",
      width: "100%",
    },
  }
  return (
    <ContentWrapper
      title={"We have sent you an email"}
      subtitle={
        "Click on the email verification link sent to you to complete the sign up."
      }
    >
      <div style={styles.footer}>
        <Text type="secondary">Didn't get it?</Text>{" "}
        <Link onClick={verifyEmail} >Click here to resend</Link>
      </div>
    </ContentWrapper>
  );
}
