import React from "react";
import { Grid, theme, Typography, Flex } from "antd";
import Logo from "../../../../assets/images/icon-black.png";

const { useToken } = theme;
const { useBreakpoint } = Grid;
const { Text, Title } = Typography;

export default function ContentWrapper({ title, subtitle, children }) {
  const { token } = useToken();
  const screens = useBreakpoint();

  const styles = {
    section: {
      alignItems: "center",
      backgroundColor: token.colorBgContainer,
      display: "flex",
      height: screens.sm ? "100vh" : "auto",
      padding: screens.md ? `${token.sizeXXL}px 0px` : "0px",
    },
    logo: {
      height: 70,
      width: 70,
      borderRadius: 10,
    },
    container: {
      margin: "0 auto",
      padding: screens.md ? `${token.paddingXL}px` : `${token.paddingXL}px ${token.padding}px`,
      width: "320px",
    },
    header: {
      marginBottom: token.marginXL,
      textAlign: "center",
    },
    text: {
      color: token.colorTextSecondary,
    },
  };

  return (
    <section style={styles.section}>
      <div style={styles.container}>
        <div style={styles.header}>
          <img
            className="logo"
            style={styles.logo}
            src={Logo}
            alt="logo"
          />

          <Title style={styles.title}>{title}</Title>
          <Text type="secondary">{subtitle}</Text>
        </div>
        <Flex
          vertical={true}
          gap={6}
        >
          {children}
        </Flex>
      </div>
    </section>
  );
}
