import React from "react";
import { Tabs } from "antd";
import ProfileForm from "../../components/forms/forms/ProfileForm/ProfileForm";
import BillingForm from "../../components/forms/forms/BillingForm/BillingForm";
import AccountForm from "../../components/forms/forms/AccountForm/AccountForm";
import ContentLayout from "../../layouts/ContentLayout/ContentLayout";
import { useGetUserDetails } from "../../apis/useUserApi";
import Tools from "./components/Tools/Tools";
import "./Settings.scss";

const TabContentWrapper = ({ children, style = {} }) => {
  return <div style={{ maxWidth: 620, ...style }}>{children}</div>;
};

export default function Settings() {
  const { data: userDetails } = useGetUserDetails();

  const items = React.useMemo(() => {
    const ret = [
      {
        label: "Profile",
        key: "profile",
        children: (
          <TabContentWrapper>
            <ProfileForm />
          </TabContentWrapper>
        ),
      },
      {
        label: "Account",
        key: "account",
        children: (
          <TabContentWrapper>
            <AccountForm />
          </TabContentWrapper>
        ),
      },
      {
        label: "Tools",
        key: "tools",
        children: (
          <TabContentWrapper style={{ maxWidth: "unset" }}>
            <Tools />
          </TabContentWrapper>
        ),
      },
    ];

    if (userDetails?.currentSubscription?.stripeCustomerId) {
      ret.push({
        label: "Billing",
        key: "billing",
        children: <BillingForm />,
      });
    }

    return ret;
  }, [userDetails]);

  return (
    <ContentLayout
      className="settings-container"
      title="Settings"
    >
      <Tabs
        className="settings-tabs-component"
        tabPosition={"left"}
        items={items}
        style={{
          marginLeft: -22,
          height: "100%",
        }}
      />
    </ContentLayout>
  );
}
