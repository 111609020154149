// Import Dayjs and required plugins
const dayjs = require("dayjs");
const relativeTime = require("dayjs/plugin/relativeTime");
const updateLocale = require("dayjs/plugin/updateLocale");
const isSameOrBefore = require("dayjs/plugin/isSameOrBefore");

// Extend Dayjs with the plugins
dayjs.extend(relativeTime);
dayjs.extend(updateLocale);
dayjs.extend(isSameOrBefore);

// Update locale for relative time
dayjs.updateLocale("en", {
  relativeTime: {
    future: "in %s",
    past: "%s ago",
    s: "a few seconds",
    m: "a minute",
    mm: "%d minutes",
    h: "an hour",
    hh: "%d hours",
    d: "a day",
    dd: "%d days",
    M: "a month",
    MM: "%d months",
    y: "a year",
    yy: "%d years",
  },
});

// Function to format timestamp
export default function formatTimestamp(timestamp) {
  const inputDate = dayjs(timestamp);
  const now = dayjs();
  const diffDays = now.diff(inputDate, "day");

  if (diffDays < 7) {
    // Return relative time for dates within the last 7 days
    return inputDate.fromNow();
  } else if (inputDate.isSame(now, "year")) {
    // Return the day of the week for dates within the current year
    return inputDate.format("dddd");
  } else {
    // Return full date for dates older than a year
    return inputDate.format("D MMMM YYYY");
  }
}
