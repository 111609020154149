import React from "react";
import ReactDOM from "react-dom/client";
import AppProviders from "./app/AppProviders";
import { App } from "antd";

import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the Data Grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the Data Grid
import "./index.scss";
import "reactflow/dist/style.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App>
      <AppProviders />
    </App>
  </React.StrictMode>
);
