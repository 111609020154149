import React from "react";
import { Flex, Space, Avatar, Typography } from "antd";
import { useGetUserDetails } from "../../../../../../apis/useUserApi";
import formatTimestamp from "../../../../../../utils/formatTimestamp";
const { Text } = Typography;

export default function OutgoingMessage({ message }) {
  const { content, timestamp } = message;
  const { data: user } = useGetUserDetails();

  const initials = React.useMemo(() => {
    if (user) {
      const firstLetter = user?.firstName?.[0] ? user.firstName[0].toUpperCase() : "";
      const lastLetter = user?.lastName?.[0] ? user.lastName[0].toUpperCase() : "";
      return `${firstLetter}${lastLetter}`;
    }
  }, [user]);

  return (
    <Flex className="msg msg-type--outgoing">
      <Space>
        <Avatar
          size={"small"}
          style={{ background: "#007FFF" }}
        >
          {initials}
        </Avatar>
      </Space>
      <Flex vertical={true}>
        <Text className="msg-content">{content}</Text>
        <Text
          className="msg-timestamp"
          type="secondary"
        >
          {formatTimestamp(timestamp)}
        </Text>
      </Flex>
    </Flex>
  );
}
