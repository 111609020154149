import React from "react";
import { Typography } from "antd";
import "./TitleDivider.scss";

const { Text } = Typography;

export default function TitleDivider({ title }) {
  return (
    <div className="title-divider-container">
      <Text strong>{title}</Text>
    </div>
  );
}
