function hasCircularStructure(nodes, edges) {
  // Step 1: Create an adjacency list representation of the graph
  const graph = {};
  for (const edge of edges) {
    if (!graph[edge.source]) {
      graph[edge.source] = [];
    }
    graph[edge.source].push(edge.target);
  }

  // Step 2: Create sets for visited nodes and the current recursion stack
  const visited = new Set();
  const recursionStack = new Set();

  // Step 3: Define the depth-first search function
  function dfs(nodeId) {
    // Mark the current node as visited and add to recursion stack
    visited.add(nodeId);
    recursionStack.add(nodeId);

    // Traverse all adjacent vertices
    if (graph[nodeId]) {
      for (const neighbor of graph[nodeId]) {
        // If the neighbor hasn't been visited, recursively call dfs
        if (!visited.has(neighbor)) {
          if (dfs(neighbor)) {
            return true; // Circular structure found
          }
        }
        // If the neighbor is in the recursion stack, we've found a cycle
        else if (recursionStack.has(neighbor)) {
          return true; // Circular structure found
        }
      }
    }

    // Remove the node from the recursion stack
    recursionStack.delete(nodeId);
    return false;
  }

  // Step 4: Call dfs for each unvisited node
  for (const node of nodes) {
    if (!visited.has(node.id)) {
      if (dfs(node.id)) {
        return true; // Circular structure found
      }
    }
  }

  // Step 5: If no circular structure found, return false
  return false;
}

export default hasCircularStructure;
