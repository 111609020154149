function parseUnitAmountDecimal(unitAmountDecimal) {
  // Ensure the input is a string
  if (typeof unitAmountDecimal !== 'string') {
    throw new Error('Input must be a string');
  }

  // Parse the input as an integer
  const amount = parseInt(unitAmountDecimal, 10);

  // Handle invalid input
  if (isNaN(amount)) {
    throw new Error('Input must be a valid integer string');
  }

  // Calculate dollars and cents
  const dollars = Math.floor(amount / 100);
  const cents = amount % 100;

  return [dollars, cents];
}

export default parseUnitAmountDecimal