import axios from "axios";
import { useNavigate } from "react-router-dom";
import useCookies from "./utils/useCookies";
import { ACCESS_TOKEN_KEY, APP_STATE } from "../constants/auth";
import useAppStore from "../context/stores/useAppStore";
import { useGoogleLogin } from "@react-oauth/google";
import { useGetUserDetails } from "../apis/useUserApi";
import { useQueryClient } from "@tanstack/react-query";

export default function useAuthApi() {
  const { setCookie } = useCookies();
  const { data: user } = useGetUserDetails();
  const { setAppState } = useAppStore();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const signin = async (credentials) => {
    try {
      const resp = await axios.post("/api/auth/sign-in", credentials);
      authenticateUser(resp.data.token);
      return resp.data;
    } catch (error) {
      console.log(error);
    }
  };

  const signinWithGithub = async (code) => {
    try {
      const resp = await axios.get("/api/auth/github/rest", {
        params: {
          oauthProvider: "github",
          requestType: "rest",
          code,
        },
      });
      // Handle the resp from your backend
      authenticateUser(resp.data.token);
      // Save the token to localStorage or context as needed
    } catch (error) {
      console.error("OAuth login failed:", error);
    }
  };

  const signup = async (data) => {
    try {
      const resp = await axios.post("/api/auth/sign-up", data);
      const token = resp.data.token;
      setCookie(ACCESS_TOKEN_KEY, token);
      setAppState(APP_STATE.unverified);
      return resp.data;
    } catch (error) {
      console.log(error);
      return error;
    }
  };

  const signout = () => {
    setCookie(ACCESS_TOKEN_KEY, null);
    setAppState(APP_STATE.unauthenticated);
    queryClient.removeQueries();
  };

  const verifyEmail = async () => {
    try {
      const resp = await axios.post("/api/auth/verify-email/request");
      return resp.data;
    } catch (error) {
      console.log(error);
      return error;
    }
  };

  const confirmEmail = async (token) => {
    try {
      const resp = await axios.post("/api/auth/verify-email/confirm", {
        token,
      });
      authenticateUser(resp.data.token);
      navigate("/");
      return resp.data;
    } catch (error) {
      console.log(error);
      return error;
    }
  };

  const authenticateUser = (token) => {
    setCookie(ACCESS_TOKEN_KEY, token);
    if (user?.memberships?.length === 0) setAppState(APP_STATE.noTenant);
    else setAppState(APP_STATE.authenticated);
  };

  return {
    signin,
    signinWithGithub,
    signout,
    signup,
    verifyEmail,
    confirmEmail,
  };
}
