import { create } from "zustand";
import { OPEN_WORKFLOW_CHAT, SELECTED_WORKFLOW_ID } from "../../constants/views";

const initWorkflowTitle = "(Untitled)";

const init = {
  openChat: localStorage.getItem(OPEN_WORKFLOW_CHAT) === "true" ? true : false,
  selectedWorkflow: null,
  editAgent: null,
  workflowTitle: initWorkflowTitle,
  openAgentModal: false,
};

const useWorkflowStore = create((set) => ({
  ...init,
  setOpenChat: (openChat) => {
    localStorage.setItem(OPEN_WORKFLOW_CHAT, openChat);
    return set({ openChat });
  },
  setSelectedWorkflow: (selectedWorkflow) => {
    if (selectedWorkflow?.id) localStorage.setItem(SELECTED_WORKFLOW_ID, selectedWorkflow.id);
    set({
      selectedWorkflow,
      workflowTitle: selectedWorkflow?.name || initWorkflowTitle,
    });
  },
  setEditAgent: (editAgent) => set({ editAgent }),
  setWorkflowTitle: (workflowTitle) => set({ workflowTitle }),
  setOpenAgentModal: (openAgentModal) => set({ openAgentModal }),
}));

export default useWorkflowStore;
