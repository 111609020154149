import React from "react";
import { Handle, Position } from "reactflow";
import { Typography, Avatar, Flex, Form, Select, Input } from "antd";
import { MODELS, TOOLS } from "../../../../../../components/forms/forms/enum";
import TitleDivider from "../TitleDivider/TitleDivider";
import FeatherIcon from "feather-icons-react";
import useWorkflowActions from "../../../../../../context/actions/useWorkflowActions";
import "./StandardNode.scss";

const { Option } = Select;
const { Text } = Typography;

const StandardNode = ({ data, isConnectable }) => {
  const { onSelectAgent } = useWorkflowActions();
  const [form] = Form.useForm();
  const [openModel, setOpenModel] = React.useState(false);
  const [openTools, setOpenTools] = React.useState(false);

  return (
    <div className="rf-standard-node">
      <Handle
        type="target"
        position={Position.Left}
        style={{ background: "#555" }}
        onConnect={(params) => console.log("handle onConnect", params)}
        isConnectable={isConnectable}
      />
      <Flex
        className="standard-node"
        vertical={true}
      >
        <Flex
          className="standard-node--header"
          justify="space-between"
          align="center"
        >
          <Flex
            align="center"
            gap={6}
          >
            <Avatar
              size={26}
              className="agent-avatar"
              icon={
                <FeatherIcon
                  icon={"user"}
                  size={16}
                />
              }
            />
            <Text strong>{data.name}</Text>
          </Flex>
          <Flex>
            <FeatherIcon
              icon={"edit-3"}
              size={16}
              style={{ cursor: "pointer" }}
              onClick={() => onSelectAgent(data)}
            />
          </Flex>
        </Flex>
        <TitleDivider title="Attributes" />
        <div className="standard-node--body">
          <Form
            name={data.name}
            layout="vertical"
            form={form}
            initialValues={data}
          >
            <Form.Item
              label="Model"
              name="model"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                size="small"
                options={MODELS}
                value={data.model}
                open={openModel}
                onBlur={() => setOpenModel(false)}
                onFocus={() => setOpenModel(true)}
              >
                {MODELS.map((item, i) => (
                  <Option
                    key={i}
                    value={item.value}
                  >
                    {item.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              label="Prompt"
              name="prompt"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input.TextArea
                size="small"
                value={data.prompt}
                rows={4}
              />
            </Form.Item>
            <Form.Item
              label="Tools"
              name="tools"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                mode="multiple"
                size="small"
                value={data.tools}
                options={TOOLS}
                open={openTools}
                onBlur={() => setOpenTools(false)}
                onFocus={() => setOpenTools(true)}
              />
            </Form.Item>
          </Form>
        </div>
      </Flex>

      <Handle
        type="source"
        position={Position.Right}
        id="b"
        // style={{ bottom: 10, top: 'auto', background: '#555' }}
        isConnectable={isConnectable}
      />
    </div>
  );
};

export default StandardNode;
