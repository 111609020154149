import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import React from "react";

import { Button, Divider, Form, Grid, Input, theme, Typography, Flex } from "antd";
import { MailOutlined, LockOutlined, GoogleOutlined } from "@ant-design/icons";
import ContentWrapper from "../components/ContentWrapper/ContentWrapper";
import useGoogleApi from "../../../apis/useGoogleApi";
import useAppStore from "../../../context/stores/useAppStore";
import { APP_STATE } from "../../../constants/auth";
const { useToken } = theme;
const { useBreakpoint } = Grid;
const { Text } = Typography;

export default function Signin() {
  const [isLoginLoading, setIsLoginLoading] = React.useState(false);
  const { token } = useToken();
  const screens = useBreakpoint();
  const { signin, signinWithGoogle } = useGoogleApi();
  const navigate = useNavigate();
  const { appState } = useAppStore();

  // const params = new URLSearchParams(window.location.search);
  // const code = params.get("code");

  const onFinish = async (values) => {
    setIsLoginLoading(true);
    await signin(values);
    setIsLoginLoading(false);
  };

  React.useEffect(() => {
    if (appState === APP_STATE.authenticated) {
      navigate("/");
    }
  }, [appState, navigate]);

  // React.useEffect(() => {
  //   signinWithGithub(code);
  // }, [code]);

  // const onSigninWithGithub = () => {
  //   // Send the access token to your backend for further processing
  //   const clientID = process.env.REACT_APP_GITHUB_CLIENT_ID; // Replace with your GitHub OAuth app's client ID
  //   const redirectURI = "http://localhost:3001"; // The URI where GitHub will redirect after authentication
  //   const githubAuthURL = `https://github.com/login/oauth/authorize?client_id=${clientID}&redirect_uri=${redirectURI}&scope=user`;

  //   window.location.href = githubAuthURL; // Redirect the user to the GitHub OAuth authorization endpoint
  // };

  const styles = {
    footer: {
      marginTop: token.marginLG,
      textAlign: "center",
      width: "100%",
    },
    forgotPassword: {
      float: "right",
    },
    social: {
      display: "flex",
      flexDirection: "column",
      gap: token.marginXS,
    },
    title: {
      fontSize: screens.md ? token.fontSizeHeading2 : token.fontSizeHeading3,
    },
  };

  return (
    <ContentWrapper
      title={"Sign in"}
      subtitle={" Welcome to GAS! Please enter your details below to sign in."}
    >
      <Form
        name="normal_login"
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        layout="vertical"
        requiredMark="optional"
      >
        <Flex
          vertical={true}
          gap={6}
        >
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                type: "email",
                message: "Please input your Email!",
              },
            ]}
          >
            <Input
              prefix={<MailOutlined />}
              placeholder="Enter your Email"
              label="Email"
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: "Please input your Password!",
              },
            ]}
          >
            <Input.Password
              prefix={<LockOutlined />}
              type="password"
              placeholder="Password"
            />
          </Form.Item>
          <Form.Item style={{ marginBottom: "0px" }}>
            <Button
              block="true"
              type="primary"
              htmlType="submit"
              loading={isLoginLoading}
            >
              Sign in
            </Button>
          </Form.Item>
        </Flex>
      </Form>
      <Divider plain>OR</Divider>
      <div style={styles.social}>
        {/* <Button onClick={onSigninWithGithub} icon={<GithubOutlined />}>
          Continue with Github
        </Button> */}
        <Button
          onClick={signinWithGoogle}
          icon={<GoogleOutlined />}
        >
          Continue with Google
        </Button>
      </div>
      <div style={styles.footer}>
        <Text type="secondary">Don't have an account?</Text> <Link to="/signup">Sign up now</Link>
      </div>
    </ContentWrapper>
  );
}
