import React from "react";
import { Form, Input, Flex, Button, Divider, InputNumber } from "antd";
import { usePutTenant } from "../../../../apis/useTenantApi";
import { useGetUserDetails } from "../../../../apis/useUserApi";

export default function AccountForm() {
  const [form] = Form.useForm();
  const putTenant = usePutTenant({ successMsg: "Updated account settings! 😎" });
  const { data: userDetails } = useGetUserDetails();

  React.useEffect(() => {
    if (userDetails?.currentTenant) {
      const tenant = userDetails.currentTenant;

      form.setFieldsValue({
        maxSpendingLimit: tenant.maxSpendingLimit,
        maxTokenInput: tenant.maxTokenInput,
        maxTokenOutput: tenant.maxTokenOutput,
        maxRecursionLimit: tenant.maxRecursionLimit,
        businessName: tenant.businessName,
        addressLine1: tenant.addressLine1,
        addressLine2: tenant.addressLine2,
        city: tenant.city,
        state: tenant.state,
        zip: tenant.zip,
        timezone: tenant.timezone,
      });
    }
  }, [form, userDetails]);

  const onSubmit = (body) => {
    putTenant({
      id: userDetails.currentTenant.id,
      body: {
        name: userDetails.currentTenant.name,
        ...body,
      },
    });
  };

  const gap = 12;

  return (
    <Flex
      vertical={true}
      gap={gap}
    >
      <Form
        name={"profile-form"}
        layout="vertical"
        form={form}
        onFinish={onSubmit}
      >
        <Flex gap={gap}>
          <Form.Item
            label="Max Spending Limit"
            name="maxSpendingLimit"
            tooltip="Set the maximum dollar amount of token consumption for the current billing period, excluding your monthly subscription. This helps manage your usage within your plan limits."
          >
            <InputNumber />
          </Form.Item>
          <Form.Item
            label="Max Token Input"
            name="maxTokenInput"
            tooltip="Set the maximum number of tokens you can input for the current billing period. This shows your remaining token allowance and helps you manage your usage."
          >
            <InputNumber />
          </Form.Item>
          <Form.Item
            label="Max Token Output"
            name="maxTokenOutput"
            tooltip="Set the maximum number of tokens you can output for the current billing period. This shows your remaining token allowance and helps you manage your usage."
          >
            <InputNumber />
          </Form.Item>
          <Form.Item
            label="Max Recursion Limit"
            name="maxRecursionLimit"
            tooltip="Set the maximum number of recursive steps allowed in a single workflow. This limit prevents excessive looping and ensures efficient task completion."
          >
            <InputNumber />
          </Form.Item>
        </Flex>
        <Divider />
        <Form.Item
          label="Business Name"
          name="businessName"
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Address Line 1"
          name="addressLine1"
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Address Line 2"
          name="addressLine2"
        >
          <Input />
        </Form.Item>
        <Flex gap={gap}>
          <Form.Item
            label="City"
            name="city"
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="State"
            name="state"
          >
            <Input />
          </Form.Item>
        </Flex>
        <Flex gap={gap}>
          <Form.Item
            label="Zip"
            name="zip"
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Business Time Zone"
            name="timezone"
          >
            <Input />
          </Form.Item>
        </Flex>
      </Form>
      <Button
        onClick={form.submit}
        type="primary"
        style={{ alignSelf: "flex-end" }}
      >
        Save Changes
      </Button>
    </Flex>
  );
}
