import { useEffect } from "react";

function useClickOutside(ref, setIsOpen, condition) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref?.current && !ref.current.contains(event.target)) {
        setIsOpen(false);
      }
    }

    // Add event listener
    document.addEventListener("mousedown", handleClickOutside);

    // Clean up the event listener
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, setIsOpen, condition]);
}

export default useClickOutside;
