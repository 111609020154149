export const primaryTheme = {
  token: {
    colorPrimary: "#1B263B",
  },
  components: {
    Select: {
      optionActiveBg: "#ececec",
      optionSelectedBg: "#dfdfdf",
    },
    Button: {},
    Menu: {
      itemSelectedBg: "#E0E1DD",
    },
    Form: {
      labelFontSize: 12,
      labelHeight: 16,
      verticalLabelPadding: 0,
      verticalLabelMargin: 0,
      itemMarginBottom: 8,
    },
  },
};
