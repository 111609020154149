import apiEndpoints from "./utils/apiEndpoints";
import queryKeys from "./utils/queryKeys";
import useGetApi from "./utils/useGetApi";
import useMutateApi from "./utils/useMutateApi";

export const usePostTenant = () => {
  return useMutateApi({
    url: apiEndpoints.tenant.details,
    method: "post",
    invalidateQueries: [queryKeys.user.tenants.all],
    // successMsg: "Workflow saved! 😎"
  });
};

export const usePutTenant = ({ successMsg }) => {
  return useMutateApi({
    url: apiEndpoints.tenant.details,
    method: "put",
    invalidateQueries: [queryKeys.user.tenants.all],
    successMsg: successMsg ? successMsg : "Updated settings! 😎",
  });
};
