import React from "react";
import FeatherIcon from "feather-icons-react";
import { Flex, Typography, theme } from "antd";
import "./Attachment.scss";

const { Text } = Typography;
const { useToken } = theme;

export default function Attachment({ filename, onRemove }) {
  const { token } = useToken();
  const [showDelete, setShowDelete] = React.useState(false);

  return (
    <Flex
      align="center"
      gap={4}
      onMouseEnter={() => setShowDelete(true)}
      onMouseLeave={() => setShowDelete(false)}
      className="attachment-container"
    >
      <FeatherIcon
        icon={"paperclip"}
        size={12}
      />
      <Text>{filename}</Text>
      {showDelete && (
        <FeatherIcon
          icon={"trash"}
          size={12}
          color={token.colorError}
          className="attachment-delete"
          onClick={onRemove}
        />
      )}
    </Flex>
  );
}
