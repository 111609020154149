import React from "react";
import { Outlet } from "react-router-dom";
import { GoogleOAuthProvider } from "@react-oauth/google";

export default function AuthLayout({ className, title, subtitle, children }) {
  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <Outlet />
    </GoogleOAuthProvider>
  );
}
