import { create } from "zustand";
import { OPEN_WORKFLOW_CHAT } from "../../constants/views";

const init = {
  openChat: localStorage.getItem(OPEN_WORKFLOW_CHAT) === "true" ? true : false,
  chatHistory: [],
  isMessageLoading: false,
};

const useChatStore = create((set) => ({
  ...init,
  setOpenChat: (openChat) => {
    localStorage.setItem(OPEN_WORKFLOW_CHAT, openChat);
    return set({ openChat });
  },
  setChatHistory: (chatHistory) => set({ chatHistory }),
  setIsMessageLoading: (isMessageLoading) => set({ isMessageLoading }),
}));

export default useChatStore;
