import React from "react";
import PriceCard from "./components/PriceCard/PriceCard";
import Loading from "../../components/Loading/Loading";
import ContentLayout from "../../layouts/ContentLayout/ContentLayout";
import { useGetProducts } from "../../apis/useStripeApi";

import "./SubscriptionPlans.scss";

export default function SubscriptionPlans() {
  const { data: products, isLoading: isProductLoading } = useGetProducts();

  if (isProductLoading) {
    return <Loading />;
  }

  return (
    <ContentLayout title="Plans and Pricing">
      <div className="price-cards">
        {products?.products
          ?.sort((a, b) => {
            return a.prices[0].unit_amount - b.prices[0].unit_amount;
          })
          .map((product, i) => (
            <PriceCard
              key={i}
              product={product}
            />
          ))}
      </div>
    </ContentLayout>
  );
}
