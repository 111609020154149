import { useQueryClient, useMutation } from "@tanstack/react-query";
import axios from "axios";
import { App } from "antd";
import useCookies from "./useCookies";
import { ACCESS_TOKEN_KEY } from "../../constants/auth";

const useMutateApi = ({
  method,
  url,
  invalidateQueries = [],
  successMsg: successMsgProp,
  infoMsg,
  options = {}, // axios options
}) => {
  const { getCookie } = useCookies();
  const queryClient = useQueryClient();
  const { message } = App.useApp();
  let successMsg = successMsgProp;

  const { mutate } = useMutation({
    mutationFn: async ({ id, body, headers = {}, params = {}, successMsg: successMsgProp }) => {
      let urlPath = url;
      if (id) urlPath = `${url}/${id}`;
      successMsg = successMsgProp;

      let axiosParams = {
        method,
        url: urlPath,
        headers: {
          Authorization: `Bearer ${getCookie(ACCESS_TOKEN_KEY)}`,
          ...headers,
        },
        options,
        params,
      };
      if (body) axiosParams.data = body;
      return await axios(axiosParams);
    },
    onSuccess: (data) => {
      invalidateQueries.forEach((key) => {
        queryClient.invalidateQueries({ queryKey: key });
      });

      if (successMsg) {
        message.success(successMsg);
      }
      if (infoMsg) {
        message.info(infoMsg);
      }

      return data;
    },
    onError: (err) => console.log("useMutateApi err", { err }),
  });

  return mutate;
};

export default useMutateApi;
