import { create } from 'zustand'
import { APP_STATE } from '../../constants/auth'

const init = {
  appState: APP_STATE.loading,
}

const useAppStore = create((set) => ({
  ...init,
  setAppState: (appState) => set({ appState }),
  reset: () => set({ ...init }),
}))

export default useAppStore;