import apiEndpoints from "./utils/apiEndpoints";
import queryKeys from "./utils/queryKeys";
import useGetApi from "./utils/useGetApi";
import useMutateApi from "./utils/useMutateApi";

export const usePostSubscriptionCheckout = () => {
  return useMutateApi({
    url: apiEndpoints.subscription.checkout,
    method: "post",
    invalidateQueries: [],
    // successMsg: "Successfully posted"
  });
};

export const usePostSubscriptionPortal = () => {
  return useMutateApi({
    url: apiEndpoints.subscription.portal,
    method: "post",
    invalidateQueries: [],
    // successMsg: "Successfully posted"
  });
};
