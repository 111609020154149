import React from "react";
import { v4 } from "uuid";
import { message } from "antd";
import {
  useDocumentsApi,
  useGetDocumentsApi,
  usePostDocumentApi,
  // useAwsUploadCredentials,
} from "../apis/useDocsApi";

export default function useMedia() {
  const { data: documents } = useGetDocumentsApi();
  const { getAwsUploadCreds, uploadToAws } = useDocumentsApi();
  const postDocument = usePostDocumentApi();

  const [uploadCreds, setUploadCreds] = React.useState(null);
  const [doc, setDoc] = React.useState(null);

  const onGetAwsUploadCredentials = async (options) => {
    const { onSuccess, onError, file } = options;

    const fileIds = v4().split("-");
    const filename = `${fileIds[fileIds.length - 1]}-${file.name}`;
    try {
      const uploadCreds = await getAwsUploadCreds(filename);
      setUploadCreds(uploadCreds);
      setDoc(file);
      await onSuccess();
      return {
        file,
        uploadCreds,
      };
    } catch (error) {
      return onError(error);
    }
  };

  const onUpload = async ({ file, uploadCredentials }) => {
    await uploadToAws(file || doc, uploadCredentials.uploadCredentials || uploadCreds.uploadCredentials);
    postDocument({
      body: {
        name: file?.name || doc?.name,
        description: "",
        metadata: JSON.stringify({
          url: uploadCredentials.downloadUrl || uploadCreds.downloadUrl,
        }),
      },
    });
  };

  const onChangeMedia = (info) => {
    if (info.file.status !== "uploading") {
      console.log(info.file, info.fileList);
    }
    if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  return {
    onGetAwsUploadCredentials,
    onUpload,
    onChangeMedia,
    doc,
  };
}
