import React from "react";
import Modal from "../../../Modal/Modal";
import { UploadOutlined } from "@ant-design/icons";
import { Button, Upload } from "antd";
import useMedia from "../../../../utils/useMedia";

export default function MediaGalleryModal({ open, onCancel }) {
  const { onGetAwsUploadCredentials, onUpload, onChangeMedia } = useMedia();

  return (
    <Modal
      open={open}
      onCancel={onCancel}
      width={800}
    >
      <Upload
        customRequest={onGetAwsUploadCredentials}
        onChange={onChangeMedia}
      >
        <Button icon={<UploadOutlined />}>Click to Upload</Button>
      </Upload>
      <Button onClick={onUpload}>Upload</Button>
    </Modal>
  );
}
