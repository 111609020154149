import React from "react";
import { useNodesState, useEdgesState } from "reactflow";

const WorkflowContext = React.createContext({});

export const WorkflowProvider = ({ children }) => {
  const [nodes, setNodes] = useNodesState([]);
  const [edges, setEdges] = useEdgesState([]);

  return (
    <WorkflowContext.Provider
      value={{
        nodes,
        edges,
        setNodes,
        setEdges,
      }}
    >
      {children}
    </WorkflowContext.Provider>
  );
};

const useWorkflowContext = () => {
  return React.useContext(WorkflowContext);
};

export default useWorkflowContext;
