import React from "react";

// providers
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { ConfigProvider, App as AntApp } from "antd";
import { BrowserRouter } from "react-router-dom";
import { WorkflowProvider } from "../context/useWorkflowContext";

// hooks
import useQueryClient from "../apis/utils/useQueryClient";

// components
import App from "./App";

// theme
import { primaryTheme } from "../styles/theme";

export default function AppProviders() {
  const { queryClient } = useQueryClient();

  return (
    <BrowserRouter>
      <ConfigProvider theme={primaryTheme}>
        <QueryClientProvider client={queryClient}>
          <WorkflowProvider>
            <App />
            <ReactQueryDevtools initialIsOpen={false} />
          </WorkflowProvider>
        </QueryClientProvider>
      </ConfigProvider>
    </BrowserRouter>
  );
}
