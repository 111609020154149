function uniqueWorkflowTitle(title, arr) {
  let str = title.trim();
  let baseTitle = str;
  let count = 1;

  // Check if the title has a number in parentheses at the end
  const match = str.match(/^(.*?)(\s*\(\d+\))$/);
  if (match) {
    baseTitle = match[1].trim();
    count = parseInt(match[2].replace(/\D/g, ""), 10) + 1;
  }

  let newStr = str;

  while (arr.includes(newStr)) {
    newStr = `${baseTitle} (${count})`;
    count++;
  }

  return newStr;
}

export default uniqueWorkflowTitle;
